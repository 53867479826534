import React from 'react';
import App, { Container } from 'next/app';
import nookies from 'nookies';

import '../src/styles/styles.scss';
import { Services, ServicesContext } from '../src/lib/services';

// initialize application container
const services = new Services();
services.initialize();

export default class XcalibraApp extends App {
  static async getInitialProps({ Component, ctx }) {
    let pageProps = {};

    const cookies = nookies.get(ctx);
    const session = cookies.accessToken
      ? {
          accessToken: cookies.accessToken,
          username: cookies.username,
        }
      : null;

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps({ ...ctx, session, services });
    }

    // TODO: Load this from CMS at some point
    // pageProps.globalAlert = 'Just a test';

    pageProps.session = session;

    return { pageProps };
  }

  componentDidMount() {
    services.start();
  }

  componentDidCatch(error, errorInfo) {
    if (console && typeof console.error === 'function') {
      // NOTE: We want this to be written out always, that's why we use console directly
      console.error(error);
      if (errorInfo && errorInfo.componentStack) {
        console.error('Component stack', errorInfo.componentStack);
      }
    }

    services.errorReporter.report('App', error, errorInfo);
  }

  render() {
    const { Component, pageProps } = this.props;

    return (
      <Container>
        <ServicesContext.Provider value={services}>
          <Component {...pageProps} />
        </ServicesContext.Provider>
      </Container>
    );
  }
}
