import { isProduction } from './settings';

const enabled =
  !isProduction &&
  typeof console !== 'undefined' &&
  console.log &&
  console.warn &&
  console.error &&
  true;

export default class Logger {
  constructor(prefix) {
    this._prefix = prefix ? `[${prefix}]` : '';
  }

  prefixed(prefix) {
    return new Logger(prefix);
  }

  verbose(...args) {
    enabled && console.log(this._prefix, ...args);
  }

  info(...args) {
    enabled && console.log(this._prefix, ...args);
  }

  warn(...args) {
    enabled && console.warn(this._prefix, ...args);
  }

  error(...args) {
    enabled && console.error(this._prefix, ...args);
  }
}
