import React from 'react';
import ErrorReporter from './error_reporter';
import Logger from './logger';
import Matomo from './matomo';

export class Services {
  logger;
  errorReporter;
  factories;

  constructor(factories) {
    this.factories = factories;
  }

  /**
   * If given service name doesn't exist, create it using either the provided factory method
   * or the default factory
   */
  provide(name, defaultFactory) {
    if (!this[name]) {
      if (this.factories && this.factories[name]) {
        this[name] = this.factories[name](this);
      } else {
        this[name] = defaultFactory(this);
      }
    }

    return this;
  }

  /**
   * Create all services using either provided factories or falling back to the defaults.
   * This is a synchronous and safe operation (there are no API requests or similar side-effects)
   */
  initialize() {
    this.provide('logger', () => new Logger());
    this.provide('errorReporter', () => new ErrorReporter());
    this.provide('matomo', () => new Matomo(this.logger));

    this.logger.info(`Services initialized`);

    return this;
  }

  /**
   * Start all the background tasks and execute initialization code
   */
  start() {
    // Init error reporting
    this.errorReporter.initialize();
    // Initialize analytics
    this.matomo.initialize();

    this.logger.info(`All services started`);
  }
}

export const ServicesContext = React.createContext(null);
