import * as Sentry from '@sentry/node';
import settings from './settings';

export default class ErrorReporter {
  sentry;

  constructor() {}

  initialize() {
    if (!this.sentry && settings.sentryDsn) {
      // Init real sentry if error server is configured
      this.sentry = Sentry;
      this.sentry.init({
        dsn: settings.sentryDsn,
        enabled: true,
        release: require('../../../package.json').version,
      });
    }
  }

  report(subsystem, err, additionalData) {
    if (!this.sentry) {
      // Nothing to do
      return;
    }

    // Try to discover an error object, if given
    let errorOb;
    if (err instanceof Error) {
      errorOb = err;
    } else if (additionalData instanceof Error) {
      errorOb = additionalData;
      // Consume additional data
      additionalData = undefined;
    }

    const code = (errorOb && errorOb.code) || 0;

    const severity =
      code >= 400 && code < 500
        ? // These are validation errors and such
          'info'
        : code > 0 && code !== NON_HTTP_ERROR_CODE
        ? // These are server errors produced by backend. They can be warnings here
          'warning'
        : // Everything else is error
          'error';

    this.sentry.withScope(scope => {
      scope.setLevel(severity);

      // Set subsystem
      scope.setExtra('subsystem', subsystem);

      // Add details from any additional passed object
      if (typeof additionalData === 'object' && !(additionalData instanceof Error)) {
        for (const key in additionalData) {
          if (Object.prototype.hasOwnProperty.call(additionalData, key)) {
            scope.setExtra(key, additionalData[key]);
          }
        }
      }

      if (errorOb) {
        // Report as error object
        this.sentry.captureException(errorOb);
      } else {
        // Report as message
        const message = (err && err.message) || (err && String(err)) || 'Error';
        this.sentry.captureMessage(message, severity);
      }
    });
  }
}
