import { Router } from 'next/router';

import settings from './settings';

export default class Matomo {
  logger;

  constructor(logger) {
    this.logger = logger.prefixed('Matomo');
  }

  push(args) {
    if (!window._paq) {
      throw new Error(`Analytics not initialized`);
    }
    if (window._paq.length === undefined || window._paq.length < 100) {
      window._paq.push(args);
    }
  }

  trackPageView(pathname = undefined) {
    const fullTitle = `[${document.domain}] ${document.title}`;
    this.push(['setDocumentTitle', fullTitle]);
    if (pathname) {
      this.push(['setCustomUrl', pathname]);
    }
    this.push(['trackPageView']);
    this.logger.verbose(`Page view: "${pathname}" Title: "${fullTitle}"`);
  }

  initialize() {
    if (!process.browser) {
      throw new Error('Analytics must be initialized client-side');
    }

    if (!settings.matomo.siteId || !settings.matomo.url) {
      this.logger.warn(`Analytics is not configured!`);
      return false;
    }

    // NOTE: Matomo will use global _paq array as source of its events
    window._paq = [];

    this.logger.info(`Initializing analytics`);

    // Encode analytics requests
    this.push([
      'setCustomRequestProcessing',
      req => {
        // NOTE: We convert query to BASE64 to get around ad-blockers. The events will go through proxy which
        //       will restore the original query params.
        return btoa(req);
      },
    ]);

    if (settings.matomo.cookieDomain) {
      this.push(['setCookieDomain', settings.matomo.cookieDomain]);
    }

    if (settings.matomo.domains && settings.matomo.domains.length) {
      this.push(['setDomains', settings.matomo.domains]);
    }

    this.push(['enableCrossDomainLinking']);
    this.push(['enableLinkTracking']);

    this.trackPageView();

    const matomoBaseUrl =
      settings.matomo.url[settings.matomo.url.length - 1] === '/'
        ? settings.matomo.url
        : settings.matomo.url + '/';

    this.push(['setTrackerUrl', matomoBaseUrl + 'event-enc']);
    this.push(['setSiteId', settings.matomo.siteId]);

    const script = window.document.createElement('script');
    const firstSibling = window.document.getElementsByTagName('script')[0];
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    script.onload = () => {
      this.logger.info(`Analytics loaded`);
    };
    script.src = matomoBaseUrl + 'xc-script.js';
    firstSibling.parentNode.insertBefore(script, firstSibling);

    Router.events.on('routeChangeStart', url => {
      this.trackPageView(url);
    });

    return true;
  }
}
